const messages = {
  en: {
    translations: {
      content: {
        title: {
          title1: 'Access to Softwares, Platforms, Apps and Portals',
          title2: 'Softwares and Hardwares',
          title3: 'Internet Connection and Networks',
          title4: 'Office 365 Platform',
          title5: 'Telephony Services',
          title6: 'License Management',
          title7: 'Projects & Systems',
          title8: 'Meeting Rooms and Events',
          title9: 'Information Security',
          title10: 'Technical Support - Ônix',
          title21: 'Voip',
        },
        subtitle: {
          subtitle1: 'Access, Revoke Access, Unlock Login and Password, Release Access to the P7000, Restore files from P7000, Restore Email Files, Report problems with the Datacenter Software and Hardware',
          subtitle2: 'Install or Repair Software, Install Digital Certificate, Replace Computer, Technical support for Smartphones and Tablets, Acquire new equipment or peripherals, Lend or return equipment, Update or configure the computer, Printers and Scanners, Configure Collectors',
          subtitle3: 'Request new network coverage demands, Cable Connection Access Problems, Wi-Fi Network Access Problems',
          subtitle4: 'Create a team in Teams, Increase storage in SharePoint, SharePoint technical support, Outlook - Create Shared Mailbox, Outlook - Create Distribution List, Outlook - Create or Edit Meeting Room, Release access for a disconnected employee',
          subtitle5: 'Change phone lines, Receive support on phone bills, Activate international roaming, Phone line malfunction, Change phone plan',
          subtitle6: 'Request Licenses, Remove Licenses, Transfer Licenses, Licensing Reports, Request Material Purchase Requisition',
          subtitle7: 'Digital Projects and Improvements, Configurations & Adjustments, Bugs',
          subtitle8: 'Configure Conference Room, Technical support at events',
          subtitle9: 'Report a threat (virus, phishing, malware), Suggest an improvement in Information Security policies',
          subtitle10: 'Camera not working, Surveillance monitoring failure, Configure Property Security Monitoring',
          subtitle21: 'The phone extension is experiencing issues with call functionality',
        },
        department: {
          department3: 'DITIE (TI)',
          department5: 'Legal',
          department4: 'DAFC',
        },
        description: {
          description3: 'Find answers, technical support and develop projects with the IT team.',
          description5: 'Find answers, technical support and develop projects with the Legal team.',
          description4: 'Find answers, technical support and develop projects with the Finance team.',
        }
      },
      children: {
        children1: 'Grant Access',
        children2: 'Revoke access',
        children3: 'Unlock Login and Password',
        children4: 'Release Access to P7000',
        children5: 'Restore Files from P7000',
        children6: 'Restore Files from Email',
        children7: 'Report problems with the Datacenter',
        children8: 'Install or Repair Software',
        children9: 'Install Digital Certificate',
        children10: 'Replace Computer',
        children11: 'Technical support for Smartphones and Tablets',
        children12: 'Acquire equipment and accessories',
        children13: 'Lend or return equipment',
        children14: 'Update or configure computer',
        children15: 'Printers and Scanners',
        children16: 'Configure Collectors',
        children17: 'Request new network coverage demands',
        children18: 'Wired network access problems',
        children19: 'Wi-Fi network access problems',
        children20: 'Create team in Teams',
        children21: 'Increase storage in SharePoint',
        children22: 'SharePoint technical support',
        children23: 'Outlook - Create Shared Mailbox',
        children24: 'Outlook - Create Distribution List',
        children25: 'Outlook - Create or Edit Meeting Room',
        children26: 'Change phone lines',
        children27: 'Receive support about phone bills',
        children28: 'Activate international roaming',
        children29: 'Telephone line malfunction',
        children30: 'Change phone plan',
        children31: 'Request Licenses',
        children32: 'Remove from licenses',
        children33: 'Transfer licenses',
        children34: 'Licensing Reports',
        children36: ' New Digital Projects',
        children37: 'Settings & Adjustments',
        children38: 'Report a system or plnpm atform failure',
        children39: 'Configure Conference Room',
        children40: 'Technical support at events',
        children41: 'Report a threat (virus, phishing, malware)',
        children42: 'Suggest an improvement in Security policies',
        children43: 'Camera Not Working',
        children44: 'Surveillance monitoring failure',
        children45: 'Configure Property Security Monitoring',
        children68: 'Request a purchase order for material or service',
        children69: 'Free access of employee off',
        children130: 'Request e-SIM',
        children132: 'Office 365 Online platform support',
        children46: 'The phone extension is experiencing issues with call functionality',
        children133: 'Grant access to USB port',
        children134: 'Creation of a Maintenance Notification',
        children151: 'Shared generic login form or totem',
        children166: 'Enable or disable international calls (extensions)',
        children195: 'Configure PowerBI update report',
      },
      forms: {
        access_forms: 'Access the forms:',
        forms: 'Forms:',
        popular: 'Popular forms:',
        unpopular: 'Other forms:',
      },
      home: {
        description: 'Hello! Find answers, support and ask your questions with our team!',
        portals: 'Portals:',
        forms: 'Recently accessed forms:',
        no_forms: 'No recent accesses',
        request: 'Requests',
      },
      translate: {
        language: 'Language',
        pt: 'Português - BR',
        en: 'English - US',
        es: 'Español - ES',
      },
      autocomplete: {
        forms: 'Search form',
        no_options: 'form not found',
      },
      information: {
        development: 'Under development',
      },
      install_pwa: {
        title: 'Install App',
        description: 'Install the app on your device',
        install: 'Install',
        close: 'Close',
      }
    }
  }
}

export { messages }

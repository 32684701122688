const messages = {
  pt: {
    translations: {
      content: {
        title: {
          title1: 'Acesso a Softwares, Plataformas, Apps e Portais',
          title2: 'Softwares e Hardwares',
          title3: 'Conexão à Internet e Redes',
          title4: 'Plataforma Office 365',
          title5: 'Serviços de Telefonia',
          title6: 'Gestão de Licenças',
          title7: 'Projetos & Sistemas',
          title8: 'Salas de Reuniões e Eventos',
          title9: 'Segurança da Informação',
          title10: 'Suporte Técnico - Ônix',
          title21: 'Voip',
        },
        subtitle: {
          subtitle1: 'Acesso, Revogar Acesso, Desbloquear Login e Senha, Liberar Acesso ao P7000, Restaurar Arquivos do P7000, Restaurar Arquivos de E-mail, Relatar problemas com o Datacenter Softwares e Hardwares',
          subtitle2: 'Instalar ou Reparar Software, Instalar Certificado Digital, Trocar Computador, Suporte técnico para Smartphones e Tablets, Adquirir um novo equipamento ou periférico, Emprestar ou devolver equipamentos, Atualizar ou configurar o computador, Impressoras e Scanners, Configurar Coletores',
          subtitle3: 'Solicitar novas demandas de cobertura de rede, Problemas de Acesso à Conexão por Cabo, Problemas de Acesso a Rede Wi-Fi',
          subtitle4: 'Criar equipe no Teams, Aumentar o armazenamento no SharePoint, Suporte técnico SharePoint, Outlook - Criar Caixa Compartilhada, Outlook - Criar Lista de Distribuição, Outlook - Criar ou Editar Sala de Reunião, Liberar acessos de colaborador desligado',
          subtitle5: 'Alterar linhas telefônicas, Receber suporte sobre contas telefônicas, Ativar roaming internacional, Mau funcionamento da linha telefônica, Alterar plano de telefonia',
          subtitle6: 'Solicitar licenças, Remover licenças, Transferir licenças, Relatórios de licenciamento, Solicitar requisição de compra de material',
          subtitle7: 'Projeto Digitais e Melhorias, Configurações & Ajustes, Bugs',
          subtitle8: 'Configurar Sala de Conferência, Suporte técnico em eventos',
          subtitle9: 'Relatar incidente de segurança da informação (vírus, mensagem suspeita, indisponibilidade de serviço, perda e roubo de dispositivo)',
          subtitle10: 'Câmera não está funcionando, Falha no monitoramento de vigilância, Configurar monitoramento de segurança patrimonial',
          subtitle21: 'Ramal não faz ou não recebe ligação',
        },
        department: {
          department3: 'DITIE (TI)',
          department5: 'Jurídico',
          department4: 'DAFC',
        },
        description: {
          description3: 'Encontre respostas, suporte técnico e desenvolva projetos com o time de TI.',
          description5: 'Encontre respostas, suporte técnico e desenvolva projetos com o time Jurídico.',
          description4: 'Encontre respostas, suporte técnico e desenvolva projetos com o time Financeiro.',
        }
      },
      children: {
        children1: 'Conceder Acesso',
        children2: 'Revogar acesso',
        children3: 'Desbloquear Login e Senha',
        children4: 'Liberar Acesso ao P7000',
        children5: 'Restaurar Arquivos do P7000',
        children6: 'Restaurar Arquivos do E-mail',
        children7: 'Relatar problemas com o Datacenter',
        children8: 'Instalar ou Reparar Software',
        children9: 'Instalar Certificado Digital',
        children10: 'Trocar Computador',
        children11: 'Suporte técnico para Smartphones e Tablets',
        children12: 'Adquirir equipamentos e acessórios',
        children13: 'Emprestar ou devolver equipamentos',
        children14: 'Atualizar ou configurar o computador',
        children15: 'Impressoras e Scanners',
        children16: 'Configurar Coletores',
        children17: 'Solicitar novas demandas de cobertura de rede',
        children18: 'Problemas de acesso à rede cabeada',
        children19: 'Problemas de acesso à rede Wi-Fi',
        children20: 'Criar equipe no Teams',
        children21: 'Aumentar o armazenamento no SharePoint',
        children22: 'Suporte técnico SharePoint',
        children23: 'Outlook - Criar Caixa Compartilhada',
        children24: 'Outlook - Criar Lista de Distribuição',
        children25: 'Outlook - Criar ou Editar Sala de Reunião',
        children26: 'Alterar linhas telefônicas',
        children27: 'Receber suporte sobre contas telefônicas',
        children28: 'Ativar roaming internacional',
        children29: 'Mau funcionamento da linha telefônica',
        children30: 'Alterar plano de telefonia',
        children31: 'Solicitar licenças',
        children32: 'Remover licenças',
        children33: 'Transferir licenças',
        children34: 'Relatórios de licenciamento',
        children36: 'Novos Projetos Digitais',
        children37: 'Configurações, ajustes e melhorias em sistemas',
        children38: 'Relatar uma falha no sistema ou plataforma',
        children39: 'Configurar Sala de Conferência',
        children40: 'Suporte técnico em eventos',
        children41: 'Relatar incidente de segurança da informação (vírus, mensagem suspeita, indisponibilidade de serviço, perda e roubo de dispositivo)',
        children42: 'Sugerir uma melhoria nas politicas de Segurança',
        children43: 'Câmera não está funcionando',
        children44: 'Falha no monitoramento de vigilância',
        children45: 'Configurar monitoramento de segurança patrimonial',
        children68: 'Solicitar requisição de compra de material ou serviço',
        children69: 'Liberar acessos de colaborador desligado',
        children130: 'Solicitar e-SIM',
        children132: 'Suporte à plataforma Office 365 Online',
        children46: 'Ramal não faz ou não recebe ligação',
        children133: 'Liberar porta USB',
        children134: 'Abertura de Nota de Manutenção',
        children151: 'Formulário para Login Genérico Compartilhado ou Totem',
        children166: 'Habilitar ou Desativar Ligações Internacionais (Ramais)',
        children195: 'Configurar Atualização de Relatórios do Power BI',
      },
      forms: {
        access_forms: 'Acesse os formulários:',
        forms: 'Formulários:',
        popular: 'Formulários populares:',
        unpopular: 'Outros formulários:',
      },
      home: {
        description: 'Olá! Encontre respostas, suporte e tire suas dúvidas com o nosso time!',
        portals: 'Portais:',
        forms: 'Formulários acessados recentemente:',
        no_forms: 'Sem acessos recentes',
        request: 'Solicitações',
      },
      translate: {
        language: 'Idioma',
        pt: 'Português - BR',
        en: 'English - US',
        es: 'Español - ES',
      },
      autocomplete: {
        forms: 'Buscar formulário',
        no_options: 'Formulário não encontrado',
      },
      information:{
        development: 'Em desenvolvimento',
      },
      install_pwa: {
        title: 'Instalar Aplicativo',
        description: 'Instale o aplicativo em seu dispositivo',
        install: 'Instalar',
        close: 'Fechar',
      },
    }
  }
}

export { messages }
